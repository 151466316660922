<template>
  <component
    :is="mergeProps.tag"
    :class="['title', `title--${mergeProps.typo}`]"
    v-html="mergeProps.value"
  />
</template>

<script>
export default {
  name: 'Title',
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    value: {
      type: String,
      default: () => {},
    },
    typo: {
      type: String,
      default: () => {},
    },
    tag: {
      type: String,
      default: () => {},
    },
  },
  computed: {
    mergeProps() {
      return {
        typo: this.typo ? this.typo : this.data ? this.data.settings?.typo : 'default',
        value: this.value || this.data.value?.title,
        tag: this.tag ? this.tag : this.data ? this.data.settings?.tag : 'h2',
      };
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
