<template>
  <main>
    <Gutenberg
      :data="post.gds_blocks"
    />
  </main>
</template>

<script>
import Gutenberg from '@/components/blocks';

import data from '@/mixins/data';

export default {
  name: 'Single',
  components: {
    Gutenberg,
  },
  mixins: [data],
};
</script>
