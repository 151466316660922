<template>
  <div class="posts">
    <Grid
      v-if="items.length > 0"
      :col="{ default: 6, m: 4 }"
    >
      <div
        v-for="(item, index) in items"
        :key="index"
        class="item"
      >
        <component
          :is="thumbs.get('default')"
          :data="item"
        />
      </div>
    </Grid>
  </div>
</template>

<script>
import Thumb from '@/components/thumb';

const thumbs = new Map();
thumbs.set('default', Thumb);

export default {
  name: 'Posts',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      thumbs,
      items: [],
    };
  },
  created() {
    this.items = this.data.value.posts.map((item) => item.post);
  },
};
</script>

<style lang="scss" scoped>

</style>
